'use strict';

var sheplersApp = {
    init: function () {
    	
    }
};

//initialize app
$(document).ready(function () {
    sheplersApp.init();
});